<template>
  <el-dialog
    :title="title + '：' + (detail.taskName || '无')"
    :visible.sync="show"
    :append-to-body="true"
    :close-on-click-modal="false"
    class="dialog-large"
  >
    <!-- 内容区域 -->
    <div>
      <div class="btns">
        <span>请按照模板格式导入派单列表：</span>
        <el-button plain @click="download" :loading="btnloadDownload">
          <i class="main-btn-icon el-icon-self-download-2-line"></i>
          <span>下载模板</span>
        </el-button>
        <div style="display: inline-block; margin-left: 12px">
          <ComponentFormImport url="TaskManage/ImportTaskAssignment" button="导入派单列表" @uploaded="onUploaded" @click="clear()" />
        </div>
      </div>
      <div v-if="mark">
        <el-divider></el-divider>
        <div class="top-box">
          <div class="top-box-item" v-for="(i, index) in topBoxData" :key="index" :style="{ 'background-color': i.bgColor }">
            <p class="label" :style="{ color: i.labelColor }">{{ i.label }}</p>
            <p class="value">{{ i.value }}</p>
          </div>
        </div>
        <TableView
          ref="vTable"
          :labels="tableLabels"
          apiName="CheckImportTaskAssignment"
          :searchList="searchList"
          resChildName="checkResultList"
          @geted="onTableGeted"
          :searchExt="{ Mark: mark }"
          tableMinHeight="350px"
          :handLoading="true"
        >
          <div v-if="totalData.checkNotPassAssignmentNum" slot="ext-table">
            <div class="tip-box">
              <span><i class="icon icon-warning el-icon-self-error-warning-fill"></i>检测到有</span>
              <span class="num">&ensp;{{ totalData.checkNotPassAssignmentNum || 0 }}条&ensp;</span>
              <span>数据未通过校验</span>
              <span class="download pointer" @click="exportWorkPeople"
                ><i class="icon icon-download el-icon-self-download-2-line"></i>下载未通过数据</span
              >
            </div>
          </div>
        </TableView>
      </div>
    </div>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formatStatus, formatStatusHasColor } from '@/configs/formatters.js'
import { downloadExcel } from '@/utils'
import ComponentFormImport from '@/components/formView/import'
const tableLabels = [
  { label: '创客姓名', prop: 'userName' },
  { label: '证件号', prop: 'idCard' },
  {
    label: '校验状态',
    prop: 'checkState',
    config: { width: 120 },
    formatter: (row, column, cellValue, index) => {
      return formatStatus([({ key: 1, label: '未校验' }, { key: 2, label: '已校验' })], cellValue)
    }
  },
  {
    label: '校验结果',
    prop: 'checkResult',
    cmp: 'html',
    formatter: (row, column, cellValue, index) => {
      return formatStatusHasColor(
        [
          { key: 1, label: '通过' },
          { key: 2, label: '不通过', color: '#f56c6c' }
        ],
        cellValue
      )
    }
  },
  {
    label: '备注',
    prop: 'checkResultRemark',
    config: { showOverflowTooltip: true }
  }
]
const searchList = [
  {
    cmp: 'input',
    label: '',
    prop: 'SearchTxt',
    config: {
      placeholder: '请输入创客姓名/证件号',
      style: { width: '300px' }
    }
  },
  {
    cmp: 'select',
    label: '校验状态',
    prop: 'CheckResult',
    options: [
      { key: 1, label: '通过' },
      { key: 2, label: '不通过' }
    ],
    config: {
      placeholder: '全部'
    }
  }
]
export default {
  components: { ComponentFormImport },
  data() {
    return {
      btnloadSubmit: false,
      show: false,
      title: '导入派单列表',
      tableLabels,
      searchList,
      mark: null,
      totalData: {},
      detail: {},
      btnloadDownload: false
    }
  },
  computed: {
    topBoxData() {
      return [
        {
          label: '校验通过人数',
          value: this.totalData.checkPassAssignmentNum || 0,
          labelColor: '#00B42A',
          bgColor: '#E8FFEA'
        },
        {
          label: '校验不通过人数',
          value: this.totalData.checkNotPassAssignmentNum || 0,
          labelColor: '#EB5757',
          bgColor: '#FFECE8'
        }
      ]
    }
  },
  methods: {
    clear() {
      this.mark = null
    },
    close() {
      this.show = false
    },
    open(e) {
      if (e) {
        this.clear()
        this.detail = e
        this.show = true
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 导出未通过
    async exportWorkPeople() {
      const res = await this.$api.ExportCheckImportTaskAssignment({ mark: this.mark, checkResult: 2 })
      downloadExcel(res, '未通过派单人员列表')
    },
    onTableGeted(response, params) {
      const { summaryData } = response
      this.totalData = summaryData || {}
      if (response.state != 2) {
        setTimeout(() => {
          this.$refs.vTable.refreshTable()
        }, 1000)
      } else {
        this.$refs.vTable.closeLoading()
      }
    },
    passingSituation() {
      // 1：有通过和不通过数据，显示二次确认弹窗；2：全部通过，正常导入（逻辑与现在导入不变）；3：全部不通过，toast提示：无法导入，没有校验通过的信息
      if (
        this.totalData &&
        this.totalData.totalAssignmentNum &&
        (this.totalData.checkPassAssignmentNum || this.totalData.checkNotPassAssignmentNum)
      ) {
        return this.totalData.checkNotPassAssignmentNum == this.totalData.totalAssignmentNum
          ? 3
          : this.totalData.checkPassAssignmentNum == this.totalData.totalAssignmentNum
          ? 2
          : 1
      }
      return 0
    },
    // 下载模板
    async download() {
      this.btnloadDownload = true
      const res = await this.$api.DownLoadTaskAssignment().finally(() => {
        this.btnloadDownload = false
      })
      downloadExcel(res, '派单导入模版')
    },
    // 导入
    onUploaded(res) {
      if (res) this.mark = res
    },
    // 提交
    async submit() {
      if (this.passingSituation() == 1) {
        this.$message.warning('无法导入，请将校验不通过的信息剔除后再上传导入')
        // this.$emit('confirm', {
        //   type: 'dispatch',
        //   mark: this.mark,
        //   passPeopleNum: this.totalData.checkPassAssignmentNum,
        //   notPassPeopleNum: this.totalData.checkNotPassAssignmentNum,
        //   form: { taskId: this.detail.id }
        // })
      } else if (this.passingSituation() == 2) {
        this.btnloadSubmit = true
        const res = await this.$api.ConfirmTaskAssignment({ taskId: this.detail.id, mark: this.mark }).finally(() => {
          this.btnloadSubmit = false
        })

        this.$emit('submit')
        this.$emit('success', { passPeopleNum: this.totalData.checkPassAssignmentNum })
        this.close()
      } else {
        this.$message.warning('无法导入，没有校验通过的信息')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.top-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  &-item {
    width: 100%;
    height: 88px;
    padding: 16px 24px;
    margin-right: 16px;
    border-radius: 8px;
    &:last-child {
      margin: 0;
    }
    p {
      margin: 0;
    }
    .label {
      margin-bottom: 4px;
      line-height: 20px;
      font-size: 14px;
    }
    .value {
      line-height: 32px;
      font-size: 24px;
      font-weight: 600;
      color: #1d2129;
      font-family: 'Bebas';
    }
  }
}
.tip-box {
  padding: 10px 12px;
  margin: -4px 0 12px;
  background-color: #fef4bf;
  .pointer {
    cursor: pointer;
  }
  .icon {
    font-size: 16px;
    &-warning {
      margin-right: 8px;
      color: #f7ba1e;
    }
    &-download {
      margin: 0 4px 0 10px;
    }
  }
  .num {
    color: #cc9213;
  }
  .download {
    color: #165dff;
    font-weight: 600;
  }
}
</style>
