<template>
  <el-dialog :visible.sync="show" :append-to-body="true" :close-on-click-modal="false" class="dialog-small import-confirm">
    <div slot="title">
      <i class="el-icon-self-alert-fill title-icon"></i>
      <h3 class="title-text">存在校验未通过信息</h3>
    </div>
    <!-- 内容区域 -->
    <div class="dialog-content">
      <p>
        本次可导入校验成功的<span class="pass">&ensp;{{ passPeopleNum }}条&ensp;</span>{{ success }}，另有<span class="not-pass"
          >&ensp;{{ notPassPeopleNum }}条&ensp;</span
        >{{ fail }}校验未通过<span class="download pointer" @click="exportWorkPeople"
          ><i class="icon icon-download el-icon-self-download-2-line"></i>下载未通过数据</span
        >
      </p>
    </div>
    <template v-if="repeatIdCardList.length">
      <el-divider></el-divider>
      <el-alert type="warning" :closable="false">
        <div>
          <p><i class="el-icon-info"></i> 校验成功的数据中含有 {{ repeatIdCardList.length }}组 相似数据，其证件号分别为：</p>
          <p v-for="item in repeatIdCardList" :key="item.idCard">
            <el-popover placement="right" trigger="hover">
              <el-table :data="item.dataList">
                <el-table-column width="100" property="userName" label="姓名"></el-table-column>
                <el-table-column width="120" property="mobile" label="手机号"></el-table-column>
                <el-table-column width="180" property="accountNumber" label="收款账号"></el-table-column>
                <el-table-column min-width="100" property="afterTaxAmount" label="税后金额">
                  <template slot-scope="{ row }">
                    <span v-format="'#,##0.00'">{{ row.afterTaxAmount || 0 }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-link type="warning" slot="reference" :data-clipboard-text="item.idCard" @click="copy"
                >{{ item.idCard }} <i class="el-icon-document-copy"></i
              ></el-link>
            </el-popover>
          </p>
          <p>点击【继续导入】，会将所有校验成功的数据导入。</p>
          <p>如需处理相似数据，请点击【取消】，并将表格修改后重新上传。</p>
        </div>
      </el-alert>
    </template>
    <span slot="footer">
      <el-button plain @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="btnloadSubmit">继续导入</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
import { downloadExcel } from '@/utils'
export default {
  data() {
    return {
      show: false,
      type: '',
      mark: '',
      form: {},
      passPeopleNum: 0,
      notPassPeopleNum: 0,
      repeatIdCardList: [],
      btnloadSubmit: false
    }
  },
  computed: {
    success() {
      switch (this.type) {
        case 'dispatch':
          return '派单人员'
        case 'workPlan':
          return '结算明细'
        case 'workPeople':
          return '创客'
        default:
          return '信息'
      }
    },
    fail() {
      switch (this.type) {
        case 'dispatch':
          return '派单人员'
        case 'workPlan':
          return '结算单'
        case 'workPeople':
          return '创客'
        default:
          return '信息'
      }
    }
  },
  methods: {
    close() {
      this.show = false
    },
    open({ type, mark, passPeopleNum, notPassPeopleNum, repeatIdCardList = [], form }) {
      if (mark) {
        this.mark = mark
        this.type = type
        this.passPeopleNum = passPeopleNum
        this.notPassPeopleNum = notPassPeopleNum
        this.repeatIdCardList = repeatIdCardList
        this.show = true
        this.form = form
      } else {
        this.$message.error('无效的信息')
      }
    },
    copy(e) {
      const clipboard = new Clipboard(e.target)
      clipboard.on('success', (e) => {
        this.$message.success('证件号已复制到剪切板')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message.error('复制失败，请手动复制')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    },
    // 导出未通过
    async exportWorkPeople() {
      let APIOPTS = '',
        title = ''
      switch (this.type) {
        case 'dispatch':
          APIOPTS = this.$api.ExportCheckImportTaskAssignment
          title = '未通过派单人员列表'
          break
        case 'workPlan':
          APIOPTS = this.$api.ExportCheckUploadWorkPlan
          title = '未通过结算单列表'
          break
        case 'workPeople':
          APIOPTS = this.$api.exportWorkPeople
          title = '未通过创客列表'
          break
        default:
          break
      }

      if (!APIOPTS || !title) return this.$message.error('无效的导出')

      const res = await APIOPTS({ mark: this.mark, checkResult: 2 })

      downloadExcel(res, title)
    },
    // 提交
    async submit() {
      this.btnloadSubmit = true

      let APIOPTS = '',
        params = null,
        msg = ''
      switch (this.type) {
        case 'dispatch':
          APIOPTS = this.$api.ConfirmTaskAssignment
          msg = '派单人员导入成功'
          params = Object.assign({ mark: this.mark }, this.form)
          break
        case 'workPlan':
          APIOPTS = this.$api.ConfirmSubmitWorkPlan
          msg = '结算单导入成功'
          params = Object.assign({ mark: this.mark }, this.form)
          break
        case 'workPeople':
          APIOPTS = this.$api.ConfirmWorkPeopleCheck
          params = { mark: this.mark }
          msg = '创客导入成功'
          break
        default:
          break
      }

      if (!APIOPTS || !msg) return this.$message.error('无效的导入')

      const res = await APIOPTS(params).finally(() => {
        this.btnloadSubmit = false
      })

      this.$message.success(msg)
      this.$emit('submit')
      this.$emit('confirm')
      this.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.import-confirm {
  ::v-deep .el-dialog {
    min-height: 100px;
  }
  .title-icon {
    vertical-align: middle;
    margin-top: 4px;
    color: #f53f3f;
    font-size: 24px;
  }
  .title-text {
    display: inline-block;
    vertical-align: middle;
    margin: 4px 0 0 8px;
    line-height: 24px;
    color: #1d2129;
    font-size: 18px;
    font-weight: 600;
  }
  .dialog-content {
    line-height: 20px;
    margin: -32px 44px 0 36px;
    color: #1d2129;
    font-size: 14px;
    p {
      margin: 0;
      .pass {
        color: #26b786;
      }
      .not-pass {
        color: #f53f3f;
      }
      .download {
        color: #165dff;
        .icon {
          margin: 0 4px 0 8px;
          font-size: 16px;
        }
      }
      .pointer {
        cursor: pointer;
      }
    }
  }
}
</style>
