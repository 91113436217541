<template>
  <DetailView
    :show.sync="show"
    :config="{
      title: '任务详情查看'
    }"
  >
    <DetailCard :title="detail.taskName" :collapse="true">
      <!-- <template #ext-title>
        <el-tag type="info" v-if="detail.auditStatus == 0">等待</el-tag>
        <el-tag type="success" v-else-if="detail.auditStatus == 1 && detail.taskState == 2">开启</el-tag>
        <el-tag type="danger" v-if="detail.auditStatus == 1 && detail.taskState == 1">关闭</el-tag>
      </template> -->
      <DescView :descLabels="descLabels" :descDatas="detail"></DescView>
    </DetailCard>
    <div v-if="show">
      <DetailTabs :tabs="tabs" :active.sync="tabName"></DetailTabs>
      <Slm v-if="tabName == 'one'" ref="Slm" :taskNo="id" :autoGrant="autoGrant" :isEnableMonthly="isEnableMonthly" :isPayNotify="isPayNotify" />
      <WorkPeople v-if="tabName == 'two' || tabName == 'three'" :tabName="tabName" :taskId="this.detail.id" ref="WorkPeople" :taskNo="id" />
      <DispatchList v-if="productTaskAssignment == 1 && tabName == 'four'" :taskId="this.detail.id" :taskNo="id" ref="DispatchList" />
    </div>
  </DetailView>
</template>

<script>
import DispatchList from './dispatchList'
import WorkPeople from './workPeople'
import Slm from './slm'
let vm = null
const descLabels = [
  { label: '任务编号', prop: 'taskNumber' },
  { label: '创建时间', prop: 'taskCreateTime' },
  {
    label: '任务类型',
    prop: 'taskType',
    cmp: 'state',
    stateOptions: [
      { key: 0, label: '结算任务' },
      { key: 1, label: '普通任务' }
    ]
  },
  { label: '任务内容', prop: 'taskDescribe' },
  { label: '验收标准', prop: 'acceptCriteria' },
  {
    label: '需求人数',
    prop: 'countLimit',
    formatter: (item, datas) => {
      return item ? datas.limitCount : '不限制'
    }
  },
  {
    label: '工作时间',
    prop: 'timeLimit',
    formatter: (item, datas) => {
      return item ? `${datas.limitTimeStart || '无'}  -  ${datas.limitTimeEnd || '无'}` : '不限制'
    }
  },
  {
    label: '工作地点',
    prop: 'addressLimit',
    formatter: (item, datas) => {
      return item ? datas.limitAddress : '不限制'
    }
  },
  {
    label: '佣金范围',
    prop: 'commissionLimit',
    formatter: (item, datas) => {
      return item ? `${vm.textFormat(datas.taskMinAmount, '#,##0.00')}  -  ${vm.textFormat(datas.taskMaxAmount, '#,##0.00')}` : '不限制'
    }
  },
  { label: '备注', prop: 'remark' }
]
export default {
  components: { DispatchList, WorkPeople, Slm },
  props: {
    productTaskAssignment: {
      default: 0
    }
  },
  data() {
    return {
      id: null,
      show: false,
      descLabels,
      showContent: false,
      detail: {},
      tabName: '',
      // 抵扣金显示
      isEnableMonthly: false,
      isPayNotify: 1,
      autoGrant: 0
    }
  },
  computed: {
    tabs() {
      const base = [
        {
          id: 'two',
          label: '创客列表'
        },
        {
          id: 'one',
          label: '结算单列表'
        }
      ]
      const dispatch =
        this.productTaskAssignment == 1
          ? [
              {
                id: 'four',
                label: '派单列表'
              }
            ]
          : []
      const other =
        this.detail.taskType == 1
          ? [
              {
                id: 'three',
                label: '领取列表'
              }
            ]
          : []
      return [...other, ...base, ...dispatch]
    }
  },
  created() {
    vm = this
  },
  methods: {
    // 数据清空
    clear() {
      this.id = null
      this.showContent = false
      this.tabName = ''
      this.detail = {}
    },
    open(obj) {
      this.clear()
      if (obj && obj.id) {
        // 查看
        // const { id, taskName, taskDescribe, taskNumber, taskState, auditStatus,taskCreateTime,taskType } = obj
        // this.detail = { id, taskName, taskDescribe, taskNumber, taskState, auditStatus,taskCreateTime,taskType }
        this.id = obj.taskNumber
        this.show = true
        this.tabName = 'one'
        this.getMerchantInfo()
        this.getDetail()
      } else {
        this.$message.error('无效的信息')
      }
    },
    // 获取商户信息
    async getMerchantInfo() {
      const res = await this.$api.GetMerchantInfo()
      this.isEnableMonthly = res.isEnableMonthly
      this.autoGrant = res.autoGrant
      this.isPayNotify = res.isPayNotify
    },
    async getDetail() {
      const res = await this.$api.GetTaskManage({ id: this.id })
      this.detail = res
    }
  }
}
</script>
