<template>
  <div>
    <el-button class="detail-tabs-btn btn-table-refresh" type="text" icon="el-icon-self-refresh-line" @click="$refs.vTable.refreshTable()"
      >刷新</el-button
    >
    <TableView
      ref="vTable"
      :hideTitle="true"
      title="结算单列表"
      :labels="tableLabels"
      apiName="GetWorkPlanPageList"
      :searchList="searchList"
      :searchExt="{ TaskNo: taskNo }"
    >
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="180" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="$refs.Info.open(row, false, autoGrant)">查看</el-button>
          <el-button type="text" class="el-button-error" v-if="row.auditState === 1" @click="delOne(row)">删除</el-button>
          <el-button
            type="text"
            v-if="row.auditState === 3 && row.grantState == 2 && !autoGrant"
            @click="$refs.DialogSHFaF.open(row, 2, isEnableMonthly, isPayNotify)"
            >确认发放</el-button
          >
          <el-button type="text" class="el-button-error" v-if="row.auditState === 3 && row.grantState == 2 && !autoGrant" @click="cancel(row)"
            >拒绝发放</el-button
          >
        </template>
      </el-table-column>
    </TableView>
    <!-- 弹窗 -->
    <DialogImport ref="DialogImport" @submit="$refs.TableView.refreshTable()"></DialogImport>
    <Info ref="Info" @submit="$refs.TableView.refreshTable()"></Info>
    <DialogSHFaF ref="DialogSHFaF" @submit="$refs.TableView.refreshTable()"></DialogSHFaF>
  </div>
</template>

<script>
import { tableFinalStatementHead, tableFinalStatementFoot } from '@/configs/tables'
import { searchFinalStatement } from '@/configs/searches'
import DialogImport from '../../workPlan/check/import'
import Info from '../../workPlan/check/info'
import DialogSHFaF from '../../workPlan/check/dialogSHFaF'
export default {
  components: { DialogImport, Info, DialogSHFaF },
  props: {
    taskNo: String,
    autoGrant: Number,
    isEnableMonthly: false,
    isPayNotify: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tableLabels: [...tableFinalStatementHead, ...tableFinalStatementFoot],
      searchList: searchFinalStatement
    }
  },
  methods: {
    // 删除
    async delOne(row) {
      await this.$confirm(`确认删除此单吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.WorkPlanDelete({ workPlanId: row.id })
      this.$message.success('此单已删除')
      this.$refs.vTable.refreshTable()
    },
    // 拒绝发放
    async cancel(row) {
      await this.$confirm(`确认拒绝发放此单吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      const res = await this.$api.WorkPlanCancel({ workPlanId: row.id })
      this.$message.success('此单已拒绝发放')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
