<template>
  <div>
    <el-button class="detail-tabs-btn btn-table-refresh" type="text" icon="el-icon-self-refresh-line" @click="$refs.vTable.refreshTable()"
      >刷新</el-button
    >
    <TableView
      ref="vTable"
      :hideTitle="true"
      title="派单列表"
      :labels="tableLabels"
      apiName="GetTaskAssignmentPageList"
      :searchList="searchList"
      :searchExt="{ TaskId: taskId }"
    >
      <!-- 操作栏 -->
      <el-table-column slot="table-item" label="操作" width="80" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="termination(row)" v-if="row.assignmentState === '进行中'">终止</el-button>
        </template>
      </el-table-column>
    </TableView>
  </div>
</template>

<script>
const tableLabels = [
  { label: '派单号', prop: 'assignmentCode' },
  { label: '姓名', prop: 'userName' },
  { label: '身份证号', prop: 'idCard' },
  { label: '状态', prop: 'assignmentState' },
  { label: '派单时间', prop: 'assignmentTime' },
  { label: '终止时间', prop: 'terminationTime' }
]
const searchList = []
export default {
  props: {
    taskNo: String,
    taskId: String
  },
  data() {
    return {
      tableLabels,
      searchList
    }
  },
  methods: {
    // 删除
    async termination(row) {
      await this.$confirm(`确认终止此人员派单吗？`, '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning'
      })
      console.log(row.id)
      const res = await this.$api.TerminationTaskAssignment({ id: row.id })
      this.$message.success('此人员派单已终止')
      this.$refs.vTable.refreshTable()
    }
  }
}
</script>
